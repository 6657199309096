export enum EmployeeStatusEnum {
    ACTIVE = 'ACTIVE',
    VERIFICATION_PENDING = 'VERIFICATION_PENDING',
    DATA_EXPORT_PENDING = 'DATA_EXPORT_PENDING',
    CONSULTATION_APPROVAL_PENDING = 'CONSULTATION_APPROVAL_PENDING',
    READY_FOR_CONSULTATION_PROCESS = 'READY_FOR_CONSULTATION_PROCESS',
    NOT_ELIGIBLE_FOR_OPTIMIZATION = 'NOT_ELIGIBLE_FOR_OPTIMIZATION',
    IN_THE_CONSULTING_PROCESS = 'IN_THE_CONSULTING_PROCESS',
    DATA_MISSING = 'DATA_MISSING',
    ERROR = 'ERROR',
    DATA_EXPORT_INCOMPLETE = 'DATA_EXPORT_INCOMPLETE',
    INACTIVE = 'INACTIVE',
    NOT_A_FINSTEIN_USER = 'NOT_A_FINSTEIN_USER',
    WAITING_ACCEPT_PRIVACY_TERMS = 'WAITING_ACCEPT_PRIVACY_TERMS',
    COP_COMPLETED_CONTRACT_SIGNING_PENDING = 'COP_COMPLETED_CONTRACT_SIGNING_PENDING',
    CONTRACT_EXPIRED = 'CONTRACT_EXPIRED',
    CONTRACT_CANCELED = 'CONTRACT_CANCELED',
    WAITING_CONTRACT_SIGNATURE = 'WAITING_CONTRACT_SIGNATURE',
    WAITING_FOR_THE_GENERATION_OF_THE_CONTRACT = 'WAITING_FOR_THE_GENERATION_OF_THE_CONTRACT',
    PROBLEM_CONTRACT_GENERATION = 'PROBLEM_CONTRACT_GENERATION',
    REJECTED = 'REJECTED',
    SALARY_INCREASE_PENDING = 'SALARY_INCREASE_PENDING',
    INCREASE_APPROVED_AND_PENDING_CONFIRMATION = 'INCREASE_APPROVED_AND_PENDING_CONFIRMATION',
    PENDING_OPTIN = 'PENDING_OPTIN',
    FINALIZED_WITHOUT_OPTIMIZATION = 'FINALIZED_WITHOUT_OPTIMIZATION',
    SCHEDULED_TO_TEMPORARY_INACTIVATION = 'SCHEDULED_TO_TEMPORARY_INACTIVATION',
    SCHEDULED_TO_PERMANENT_INACTIVATION = 'SCHEDULED_TO_PERMANENT_INACTIVATION',
    TEMPORARY_DEACTIVATED = 'TEMPORARY_DEACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}

export enum EmployeeCreationType {
    PORTAL = 'PORTAL',
    INVITE_LINK = 'INVITE_LINK',
}
